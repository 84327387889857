@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'SourceSansPro';
  src: url('./fonts/source-sans-pro-v21-latin-regular.eot');
  src: url('./fonts/source-sans-pro-v21-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/source-sans-pro-v21-latin-regular.woff2') format('woff2'),
    url('./fonts/source-sans-pro-v21-latin-regular.woff') format('woff'),
    url('./fonts/source-sans-pro-v21-latin-regular.ttf') format('truetype'),
    url('./fonts/source-sans-pro-v21-latin-regular.svg#jlicons') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('./fonts/source-sans-pro-v21-latin-600.eot');
  src: url('./fonts/source-sans-pro-v21-latin-600.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/source-sans-pro-v21-latin-600.woff2') format('woff2'),
    url('./fonts/source-sans-pro-v21-latin-600.woff') format('woff'),
    url('./fonts/source-sans-pro-v21-latin-600.ttf') format('truetype'),
    url('./fonts/source-sans-pro-v21-latin-600.svg#jlicons') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RobotoCondensed';
  src: url('./fonts/roboto-condensed-v25-latin-regular.eot');
  src: url('./fonts/roboto-condensed-v25-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/roboto-condensed-v25-latin-regular.woff2') format('woff2'),
    url('./fonts/roboto-condensed-v25-latin-regular.woff') format('woff'),
    url('./fonts/roboto-condensed-v25-latin-regular.ttf') format('truetype'),
    url('./fonts/roboto-condensed-v25-latin-regular.svg#jlicons') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RobotoCondensed';
  src: url('./fonts/roboto-condensed-v25-latin-700.woff2') format('woff2'),
    url('./fonts/roboto-condensed-v25-latin-700.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'SourceSansPro', 'RobotoCondensed', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  cursor: pointer;
  color: #007085;
}
a:visited {
  color: #007085;
}
.fhir-container__ResourceContainer__json-button-wrapper {
  display: none;
}
